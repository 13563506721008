import type { TooltipProps as MUITooltipProps } from "@mui/material";
import { Tooltip as MUITooltip, Zoom } from "@mui/material";
import { forwardRef } from "react";
import Text from "./Text";

export type TooltipProps = MUITooltipProps & {
  shortcutKeys?: string[];
};

export const Tooltip = forwardRef<unknown, TooltipProps>((props, ref) => {
  const { arrow = true, shortcutKeys, title, TransitionComponent = Zoom, ...rest } = props;

  return (
    <MUITooltip
      ref={ref}
      {...rest}
      arrow={arrow}
      TransitionComponent={TransitionComponent}
      title={
        title ? (
          <Text
            component="span"
            color="white"
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {title}
            {shortcutKeys && shortcutKeys.length > 0 && (
              <Text
                component="span"
                sx={{
                  color: "grey.200",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 12,
                  my: 0.5,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {shortcutKeys.map((shortcutKey, i) => (
                  <Text component="span" display="flex" key={i}>
                    {i > 0 && (
                      <Text color="white" size="sm">
                        &nbsp;+&nbsp;
                      </Text>
                    )}
                    <Text
                      color="white"
                      mx={0.5}
                      px={0.5}
                      size="sm"
                      sx={{ backgroundColor: "grey.700", borderRadius: 1 }}
                    >
                      {shortcutKey}
                    </Text>
                  </Text>
                ))}
              </Text>
            )}
          </Text>
        ) : undefined
      }
    />
  );
});

export default Tooltip;
