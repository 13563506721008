import type { CSSProperties, FC } from "react";

type AppStoreLogoProps = {
  iconsStyle: CSSProperties;
};

const AppStoreLogo: FC<AppStoreLogoProps> = ({ iconsStyle }) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img
    style={iconsStyle}
    src="https://dialonce-cdn.s3.eu-central-1.amazonaws.com/img/dialonce/app_store.png"
    alt="Logo de l'App Store"
  />
);

export default AppStoreLogo;
