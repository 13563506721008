import type { PushWrapperFn } from "../../../types/scenarioAnalytics";
import { toGlobalId } from "../globalId";

type HandleIFrameActionArgs = {
  id: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  routerPush: PushWrapperFn;
  toPageKey?: string;
  fromIntentId?: string;
};

export const handleIFrameAction = async ({
  id,
  shortCode,
  scenarioId,
  routerPush,
  toPageKey,
  fromIntentId,
}: HandleIFrameActionArgs) => {
  try {
    const encodedId = toGlobalId("ScenarioVersionChildSolution", id);
    const url = shortCode ? `/c/${shortCode}/s/${encodedId}` : `/p/${scenarioId}/s/${encodedId}`;
    await routerPush(url, { toPageKey, fromIntentId });
    return true;
  } catch (_) {
    return false;
  }
};
