import type { FC } from "react";
import type { ActionTypeAppOS } from "..";
import PhoneNumberSolutionCard from "./PhoneNumberSolutionCard";

type PhoneNumberNodeProps = {
  phoneNumber: string;
  userOs: ActionTypeAppOS;
  pinCode?: string;
  isDigicall: boolean;
  isFastDialEnabled: boolean;
  phoneCardText: string;
};

const PhoneNumberNode: FC<PhoneNumberNodeProps> = ({
  phoneNumber,
  userOs,
  pinCode,
  isDigicall,
  isFastDialEnabled,
  phoneCardText,
}) => (
  <PhoneNumberSolutionCard
    className="ich3-action-phoneNumber"
    title={phoneCardText}
    phoneNumber={phoneNumber}
    userOs={userOs}
    pinCode={pinCode}
    isDigicall={isDigicall}
    isFastDialEnabled={isFastDialEnabled}
  />
);

export default PhoneNumberNode;
