import type { ActionEmail } from "../../../types/common";
import { getEmailUrl } from "../email";

export const handleEmailAction = ({ to: toEmail, subject, body, cc, bcc }: ActionEmail) => {
  if (toEmail) {
    const emailUrl = getEmailUrl({ to: toEmail, subject, body, cc, bcc });
    window.open(emailUrl, "_blank");
    return true;
  } else {
    // eslint-disable-next-line
    console.error("No toEmail provided");
    return false;
  }
};
