import type { SxProps, Theme } from "@mui/material";
import { forwardRef } from "react";
import { Text } from "../../data-display/Text";
import { Button } from "../../input-selection";
import { Box } from "../../layout";
import type { AppBarLink } from "./AppBar";
import { getKey } from "./getKey";
import { getLinkColor } from "./getLinkColor";
import { getNextLink } from "./getNextLink";

export type HorizontalMenuProps = {
  id: string;
  items?: AppBarLink[];
  sx?: SxProps<Theme>;
  pathname?: string;
};

export const HorizontalMenu = forwardRef<HTMLDivElement, HorizontalMenuProps>(
  ({ id, items = [], sx, pathname, ...props }, ref) => {
    if (items.length === 0) {
      return null;
    }

    return (
      <Box ref={ref} id={id} sx={sx} {...props}>
        {items.map(({ label, link, nextLink }) => (
          <Button
            color={getLinkColor(link, pathname)}
            href={link}
            key={getKey(link)}
            nextLink={getNextLink(link, nextLink)}
            size="lg"
            sx={{ mx: 2 }}
            tabIndex={-1}
            variant="text"
          >
            <Text> {label}</Text>
          </Button>
        ))}
      </Box>
    );
  }
);
