import { Box, Button } from "@mui/material";
import type { FC } from "react";
import { CrossToCloseIcon } from "../atoms";

export type Props = {
  onClickCloseButton: () => void;
};

const CloseButton: FC<Props> = ({ onClickCloseButton }) => {
  const label = navigator.language === "fr-FR" ? "Fermer" : "Close";

  return (
    <Button id="ich-close-button" type="button" onClick={onClickCloseButton} sx={{ minWidth: 0 }}>
      <CrossToCloseIcon id="ich-close-button-icon" />
      <Box
        id="ich-close-button-label"
        sx={{
          textTransform: "initial",
        }}
      >
        {label}
      </Box>
    </Button>
  );
};

export default CloseButton;
