import type { PropsWithChildren } from "react";
import { forwardRef } from "react";
import type { TableProps as MUITableProps } from "@mui/material";
import { Table as MUITable } from "@mui/material";
import { classNames } from "../../../helpers";

export type TableProps = Omit<MUITableProps, "padding"> & {
  borderless?: boolean;
  withLastBorder?: boolean;
  caption?: string;
} & PropsWithChildren;

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ borderless, className, children, caption, withLastBorder = false, ...props }, ref) => {
    return (
      <MUITable
        ref={ref}
        className={classNames(
          borderless && "borderless",
          !caption && !withLastBorder ? "withoutLastBorder" : "",
          className
        )}
        {...props}
      >
        {caption && <caption>{caption}</caption>}
        {children}
      </MUITable>
    );
  }
);
