import type { FC } from "react";
import { HtmlNode, IntentNode, SolutionNode, DelayDisplay, IAdvizeNode } from "../ui";
import type { Action, NodeType } from "../../types";
import { SolutionActionType } from "../../types";

export type Props = {
  type: NodeType;
  view: string;
  delay: number;
  actionType?: SolutionActionType;
  action?: Action;
  onClickIntent?: () => void;
  onClickSolution?: () => void;
  currentIntegrationType: string;
};

const renderNode = ({
  type,
  view,
  actionType,
  onClickIntent,
  onClickSolution,
  action,
}: Omit<Props, "enabled" | "isActiveInTimetable" | "integrationType" | "delay">) => {
  switch (type) {
    case "HTML":
      return <HtmlNode view={view} />;

    case "intent":
      return (
        <IntentNode
          view={view}
          onClick={
            // eslint-disable-next-line
            onClickIntent ? onClickIntent : () => console.error("onClickIntent func expected")
          }
        />
      );

    case "solution":
      if (!actionType) return null;
      if (actionType === SolutionActionType.liveChat && action && action.sid) {
        const { onlineId, offlineId, offlineContent, onlineContent, displayByDefault } = action;
        return (
          <IAdvizeNode
            onlineId={onlineId}
            offlineId={offlineId}
            onlineContent={onlineContent}
            offlineContent={offlineContent}
            displayByDefault={displayByDefault}
            onClick={onClickSolution}
          />
        );
      }
      return (
        <SolutionNode
          view={view}
          onClick={
            // eslint-disable-next-line
            onClickSolution ? onClickSolution : () => console.error("onClickSolution func expected")
          }
          actionType={actionType}
          code={action?.code}
        />
      );

    case "accordion":
      return null;

    default: {
      return null;
    }
  }
};

const Node: FC<Props> = ({
  type,
  view,
  delay,
  actionType,
  onClickIntent,
  onClickSolution,
  currentIntegrationType,
  action,
}) => {
  if (!type) return null;
  return (
    <DelayDisplay delay={delay}>
      {renderNode({
        type,
        view,
        actionType,
        onClickIntent,
        onClickSolution,
        currentIntegrationType,
        action,
      })}
    </DelayDisplay>
  );
};

export default Node;
