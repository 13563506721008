import { getScenarioDetails, getScenarioPage, getScenarioSolutionPage } from "../requests";
import type { ScenarioDetails } from "../..";
import type { UseQueryOptions } from "@tanstack/react-query";

type GetAllQueriesArgs = {
  preview: boolean;
  solution: boolean;
  pageKey?: string;
  solutionId?: string;
  scenarioId?: string;
  shortCode?: string;
  detailsData?: ScenarioDetails;
  currentIntegrationType: string;
};

export const getAllQueries = ({
  preview,
  solution,
  pageKey,
  solutionId,
  scenarioId,
  currentIntegrationType,
  shortCode,
  detailsData,
}: GetAllQueriesArgs) => {
  const queries: UseQueryOptions[] = [];
  if (preview) {
    if (solution) {
      queries.push({
        queryKey: ["scenarioSolution", solutionId, scenarioId, currentIntegrationType],
        queryFn: () =>
          getScenarioSolutionPage({
            solutionId,
            scenarioId,
            currentIntegrationType,
          }),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      });
    } else {
      if (pageKey) {
        queries.push({
          queryKey: ["scenarioPage", pageKey, scenarioId, currentIntegrationType],
          queryFn: () =>
            getScenarioPage({
              pageKey,
              scenarioId,
              currentIntegrationType,
            }),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        });
      } else {
        queries.push({
          queryKey: ["scenarioDetails", scenarioId, currentIntegrationType],
          queryFn: () => getScenarioDetails({ scenarioId, currentIntegrationType }),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        });
      }
    }
  } else {
    if (solution) {
      queries.push({
        queryKey: ["scenarioSolution", solutionId, shortCode, currentIntegrationType],
        queryFn: () =>
          getScenarioSolutionPage({
            solutionId,
            shortCode,
            currentIntegrationType,
          }),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      });
    } else {
      if (pageKey) {
        queries.push({
          queryKey: ["scenarioPage", pageKey, shortCode, currentIntegrationType],
          queryFn: () =>
            getScenarioPage({
              pageKey,
              shortCode,
              currentIntegrationType,
            }),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        });
      } else {
        queries.push({
          queryKey: ["scenarioDetails", shortCode, currentIntegrationType],
          queryFn: () => getScenarioDetails({ shortCode, currentIntegrationType }),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        });
      }
    }
  }

  if ((scenarioId || shortCode) && (pageKey || solutionId) && !detailsData) {
    queries.push({
      queryKey: ["scenarioDetails", scenarioId, shortCode, currentIntegrationType],
      queryFn: () =>
        getScenarioDetails({
          scenarioId,
          shortCode,
          currentIntegrationType,
        }),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });
  }

  return queries;
};
