import type { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { DelayDisplay } from "../ui";

export type Props = {
  className?: string | null;
  delay: number;
  currentIntegrationType: string;
} & PropsWithChildren;

const BlockNode: FC<Props> = ({ className, delay, children }) => {
  return (
    <DelayDisplay className={clsx("ich-block", className)} delay={delay}>
      {children}
    </DelayDisplay>
  );
};

export default BlockNode;
