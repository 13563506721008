import type { Signal } from "@preact/signals-react";
import { z } from "zod";

import type { ContextOperations, NodeType } from "../../types/api";
import type { Action } from "../../types/common";
import type { ContextKeysRecord } from "../../types/scenarioDetails";
import type { GenericObject } from "../stores";
import { scenarioParams } from "../stores";
import { updateContrackContext } from "./requests";

export function isElementDisplayableAccordingToContext(
  contextKeys: ContextKeysRecord,
  context: GenericObject,
  elementContextId: string
) {
  const { items } = contextKeys;
  const contextObject = items.find((element) => element._id === elementContextId);

  if (!contextObject) {
    return true;
  }

  const { value } = contextObject;

  return value in context;
}

const transformationFunctionSchema = z.object({
  view: z.string(),
  actionValues: z.record(z.string(), z.unknown()).optional(),
});

export type TransformationFunctionResult = z.infer<typeof transformationFunctionSchema>;

export type RunTransformationFunctionParameters = {
  serializedFunction: string;
  context: GenericObject;
  type: NodeType;
  view: string;
  action?: Action;
  analyticSessionId?: string;
  scenarioSessionId?: string;
};

export function runTransformationFunction({
  serializedFunction,
  context,
  type,
  view,
  action,
  analyticSessionId,
  scenarioSessionId,
}: RunTransformationFunctionParameters): TransformationFunctionResult | undefined {
  try {
    const unserializedFunction = new Function("return " + serializedFunction)();

    const result = unserializedFunction(
      context,
      type,
      view,
      action,
      analyticSessionId,
      scenarioSessionId
    );

    const validation = transformationFunctionSchema.safeParse(result);

    if (!validation.success) {
      throw new Error("Transform function does not respect expected output format");
    }

    return result;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error("Failed to run transformation function : %o", error);
    return;
  }
}

export function applyContextOperationsToContext(
  context: Signal<GenericObject>,
  operations: ContextOperations
) {
  operations.keysToUpsert.forEach(({ key, value }) => {
    context.value[key] = value;
  });

  operations.keysToDelete.forEach((key) => {
    delete context.value[key];
  });

  window.context = context.value;
  // eslint-disable-next-line no-console
  if (window.debugContext) console.log("Current context : ", window.context);
}

export async function syncContextWithContrack(context: GenericObject, shortCode?: string) {
  try {
    const { pinCode } = scenarioParams.value;

    if (!pinCode || !shortCode) {
      if (window.debugContext) {
        // eslint-disable-next-line no-console
        console.log(
          `Cannot update contrack (shortCode: ${shortCode}, pinCode: ${pinCode}, context: ${JSON.stringify(
            context
          )}`
        );
      }
      return;
    }

    await updateContrackContext(shortCode, pinCode, context);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Failed to sync context with contrack for short code ${shortCode}, error %o`,
      error
    );
  }
}
