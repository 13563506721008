import type {
  ClientScenarioPageDropdownChildDocument,
  ContextKeysRecord,
  DynamicUrls,
  PushWrapperFn,
} from "../../../types";
import { NodeType } from "../../../types";
import type {
  ApiScenarioPageAccordion,
  ApiScenarioPageBlock,
  ApiScenarioPageChildCommon,
  ApiScenarioPageChildDocument,
  ApiScenarioPageDropdown,
  ApiScenarioPageHtml,
  ApiScenarioPageIntent,
  ApiScenarioPageSolution,
} from "../../../types/api/scenarioVersionChildren";
import { STORAGE_ANALYTIC_ID_KEY, STORAGE_SESSION_ID_KEY } from "../../constants";
import { getInSessionStorage, handleClickIntent, handleClickSolution } from "../../helpers";
import { context } from "../../stores/scenarioContentContext";
import { AccordionNode, BlockNode, DropdownNode, Node } from "../../ui";
import type { TransformationFunctionResult } from "../context";
import {
  applyContextOperationsToContext,
  isElementDisplayableAccordingToContext,
  runTransformationFunction,
  syncContextWithContrack,
} from "../context";
import { parseSolutionActionFromApi } from "./parseSolution";

type RenderElementsArgs = {
  branch: string;
  contextRecord?: ContextKeysRecord;
  currentIntegrationType: string;
  dynamicUrls: DynamicUrls;
  elements: ApiScenarioPageChildDocument[];
  routerPush: PushWrapperFn;
  scenarioId?: string;
  shortCode?: string;
};
export const renderElements = ({
  branch,
  contextRecord,
  currentIntegrationType,
  dynamicUrls,
  elements,
  routerPush,
  scenarioId,
  shortCode,
}: RenderElementsArgs) => {
  if (elements && elements.length > 0) {
    return elements.map((element: ApiScenarioPageChildDocument) => {
      const { type, id, delay, transformFct, contextId } = element;
      let transformedParameters: TransformationFunctionResult | undefined;

      if (
        contextRecord &&
        contextId &&
        !isElementDisplayableAccordingToContext(contextRecord, context.value, contextId)
      ) {
        return null;
      }

      if (type === NodeType.accordion) {
        const { openByDefault, className, title, children } =
          element as ApiScenarioPageChildCommon & ApiScenarioPageAccordion;
        return (
          <AccordionNode
            key={id}
            openByDefault={openByDefault}
            className={className}
            title={title}
            delay={delay}
            currentIntegrationType={currentIntegrationType}
          >
            {renderElements({
              branch,
              contextRecord,
              currentIntegrationType,
              dynamicUrls,
              elements: children,
              routerPush,
              scenarioId,
              shortCode,
            })}
          </AccordionNode>
        );
      } else if (type === NodeType.block) {
        const { className, children } = element as ApiScenarioPageChildCommon &
          ApiScenarioPageBlock;
        return (
          <BlockNode
            key={id}
            className={className}
            delay={delay}
            currentIntegrationType={currentIntegrationType}
          >
            {renderElements({
              branch,
              contextRecord,
              currentIntegrationType,
              dynamicUrls,
              elements: children,
              routerPush,
              scenarioId,
              shortCode,
            })}
          </BlockNode>
        );
      } else if (type === NodeType.dropdown) {
        const { children, className, buttonText, introduction, placeholder, isActiveInTimetable } =
          element as ApiScenarioPageChildCommon & ApiScenarioPageDropdown;
        return (
          <DropdownNode
            key={id}
            childElements={children as ClientScenarioPageDropdownChildDocument[]}
            className={className}
            delay={delay}
            buttonText={buttonText}
            introduction={introduction}
            placeholder={placeholder}
            isActiveInTimetable={isActiveInTimetable}
            currentIntegrationType={currentIntegrationType}
            onSubmitIfIntent={(selectedNode) =>
              handleClickIntent({
                to: selectedNode?.to,
                routerPush,
                shortCode,
                scenarioId,
                intentId: id,
                branch,
                dynamicUrls,
              })
            }
            onSubmitIfSolution={(selectedNode) =>
              handleClickSolution({
                routerPush,
                shortCode,
                scenarioId,
                branch,
                dynamicUrls,
                ...selectedNode,
              })
            }
          />
        );
      } else if (type === NodeType.solution) {
        const {
          view,
          action: actionFromApi,
          actionType,
        } = element as ApiScenarioPageChildCommon & ApiScenarioPageSolution;

        const action = parseSolutionActionFromApi(actionFromApi);
        if (!action) return null;

        if (transformFct) {
          transformedParameters = runTransformationFunction({
            serializedFunction: transformFct,
            context: context.value,
            type,
            view,
            action,
            analyticSessionId: getInSessionStorage(STORAGE_ANALYTIC_ID_KEY) || undefined,
            scenarioSessionId: getInSessionStorage(STORAGE_SESSION_ID_KEY) || undefined,
          });
        }

        return (
          <Node
            key={id}
            type={type}
            view={transformedParameters?.view ?? view}
            delay={delay}
            currentIntegrationType={currentIntegrationType}
            actionType={actionType}
            action={action}
            onClickSolution={() =>
              handleClickSolution({
                actionType,
                shortCode,
                scenarioId,
                routerPush,
                branch,
                dynamicUrls,
                id,
                ...action,
                ...transformedParameters?.actionValues,
              })
            }
          />
        );
      } else if (type === NodeType.intent) {
        const { view, to, contextOperations } = element as ApiScenarioPageChildCommon &
          ApiScenarioPageIntent;

        if (transformFct) {
          transformedParameters = runTransformationFunction({
            serializedFunction: transformFct,
            context: context.value,
            type,
            view,
            analyticSessionId: getInSessionStorage(STORAGE_ANALYTIC_ID_KEY) || undefined,
            scenarioSessionId: getInSessionStorage(STORAGE_SESSION_ID_KEY) || undefined,
          });
        }
        return (
          <Node
            key={id}
            type={type}
            view={transformedParameters?.view ?? view}
            delay={delay}
            currentIntegrationType={currentIntegrationType}
            onClickIntent={() => {
              if (contextOperations) {
                try {
                  applyContextOperationsToContext(context, contextOperations);
                  void syncContextWithContrack(context.value, shortCode);
                } catch (error: unknown) {
                  // eslint-disable-next-line no-console
                  console.error(error); //TODO better error handling?
                }
              }
              return void handleClickIntent({
                to,
                shortCode,
                scenarioId,
                routerPush,
                intentId: id,
                branch,
                dynamicUrls,
              });
            }}
          />
        );
      } else {
        const { view } = element as ApiScenarioPageChildCommon & ApiScenarioPageHtml;

        if (transformFct) {
          transformedParameters = runTransformationFunction({
            serializedFunction: transformFct,
            context: context.value,
            type,
            view,
            analyticSessionId: getInSessionStorage(STORAGE_ANALYTIC_ID_KEY) || undefined,
            scenarioSessionId: getInSessionStorage(STORAGE_SESSION_ID_KEY) || undefined,
          });
        }
        return (
          <Node
            key={id}
            type={type}
            view={transformedParameters?.view ?? view}
            delay={delay}
            currentIntegrationType={currentIntegrationType}
          />
        );
      }
    });
  } else return null;
};
