import type { CheckboxProps as MUICheckboxProps, SxProps, Theme } from "@mui/material";
import { Box, FormControlLabel, FormGroup, Checkbox as MUICheckbox } from "@mui/material";
import type { ChangeEvent, ReactNode } from "react";
import { forwardRef, useState } from "react";
import type { CheckboxColor, CheckboxSize } from "../../types/checkbox";

type CheckboxProps = Omit<MUICheckboxProps, "color" | "isDisabled" | "required" | "size"> & {
  color?: CheckboxColor;
  defaultChecked?: boolean;
  disabled?: boolean;
  label?: string | ReactNode;
  nestedCheckboxes?: NestedCheckboxes[];
  size?: CheckboxSize;
  labelStyle?: SxProps<Theme>;
};

type NestedCheckboxes = {
  defaultChecked: boolean;
  label: string;
};

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      sx,
      size = "medium",
      color = "secondary",
      disabled = false,
      defaultChecked,
      label = "label",
      nestedCheckboxes,
      labelStyle,
      ...props
    },
    ref
  ) => {
    const [childrenChecked, setChildrenChecked] = useState(
      nestedCheckboxes?.map((checkbox) => checkbox.defaultChecked)
    );

    const handleParentChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChildrenChecked(nestedCheckboxes?.map(() => event.target.checked));
    };

    const handleChildrenChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
      if (childrenChecked) {
        const newValues = [...childrenChecked];
        newValues[index] = event.target.checked;
        setChildrenChecked(newValues);
      }
    };

    const commonProps: MUICheckboxProps = {
      size,
      color,
      disabled,
      defaultChecked,
      sx: {
        ...sx,
      },
      ...props,
      ref,
    };

    const children = (
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {nestedCheckboxes?.map((checkbox, index) => (
          <FormControlLabel
            sx={labelStyle}
            key={index}
            label={checkbox.label}
            control={
              <MUICheckbox
                {...commonProps}
                checked={childrenChecked ? childrenChecked[index] : false}
                onChange={(event) => handleChildrenChange(event, index)}
              />
            }
          />
        ))}
      </Box>
    );

    const checked = childrenChecked?.length
      ? childrenChecked.every((isChildrenChecked) => isChildrenChecked)
      : undefined;
    return (
      <FormGroup>
        <FormControlLabel
          sx={labelStyle}
          ref={ref}
          label={label}
          control={
            <MUICheckbox
              checked={checked}
              indeterminate={
                childrenChecked?.length && !checked
                  ? childrenChecked?.some((isChildrenChecked) => isChildrenChecked)
                  : undefined
              }
              onChange={childrenChecked?.length ? handleParentChange : undefined}
              {...commonProps}
            />
          }
        />
        {childrenChecked && children}
      </FormGroup>
    );
  }
);
