import clsx from "clsx";
import type { FC, PropsWithChildren, SyntheticEvent } from "react";
import { useState } from "react";
import { DelayDisplay } from "../ui";

export type Props = {
  openByDefault: boolean;
  title?: string | null;
  className?: string | null;
  delay: number;
  currentIntegrationType: string;
} & PropsWithChildren;

const AccordionNode: FC<Props> = ({ openByDefault, title, className, children, delay }) => {
  const [isOpen, setIsOpen] = useState(openByDefault ?? false);

  const handleTitleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const renderTitle = () => {
    const className = clsx("accordion-title", {
      active: isOpen,
    });

    return (
      <button className={className} onClick={handleTitleClick}>
        {title}
      </button>
    );
  };

  const classNameAccordionPanel = clsx("accordion-panel", {
    active: isOpen,
  });

  return (
    <DelayDisplay delay={delay} className={clsx("ich-accordion", className)}>
      {renderTitle()}
      {isOpen ? <div className={classNameAccordionPanel}>{children}</div> : <div />}
    </DelayDisplay>
  );
};

export default AccordionNode;
