import React, { forwardRef, useState } from "react";

import type {
  ToggleButtonGroupProps as MUIToggleButtonGroupProps,
  ToggleButtonProps as MUIToggleButtonProps,
} from "@mui/material";
import {
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
} from "@mui/material";

import { ButtonToggleBorder, ButtonTogglePadding } from "../../constants/toggleButton";
import type { ToggleButtonOrientation, ToggleButtonSize } from "../../types/toggleButton";

type ToggleButtons = {
  label: React.ReactNode | string;
  value: string;
  defaultSelected: boolean;
};

export type ToggleButtonProps = Omit<MUIToggleButtonProps, "variant" | "size" | "color"> &
  Omit<MUIToggleButtonGroupProps, "variant" | "size" | "color" | "orientation"> & {
    toggleButtons: ToggleButtons[];
    size?: ToggleButtonSize;
    orientation?: ToggleButtonOrientation;
  };

export const ToggleButton = forwardRef(
  (
    { sx, toggleButtons, size = "medium", orientation = "horizontal", ...props }: ToggleButtonProps,
    ref
  ) => {
    const defaultValueSelected = toggleButtons.filter(
      (toggleButton) => toggleButton.defaultSelected
    )[0].value;
    const [buttonSelected, setButtonSelected] = useState<string | undefined>(defaultValueSelected);

    const handleButtonChange = (
      event: React.MouseEvent<HTMLElement>,
      newValue: string | undefined
    ) => {
      setButtonSelected(newValue);
    };

    const toggleButtonGroupProps: MUIToggleButtonGroupProps = {
      size,
      orientation,
      sx: {
        ...sx,
      },
      ...props,
    };

    const toggleButtonProps: MUIToggleButtonProps = {
      sx: {
        padding: ButtonTogglePadding[size],
      },
      ...props,
    };

    const borderForToggleButton = (index: number) => {
      if (index !== 0 && index !== toggleButtons.length - 1) {
        return {
          sx: {
            padding: ButtonTogglePadding[size],
            ...ButtonToggleBorder[orientation],
          },
        };
      }
      return null;
    };

    return (
      <MUIToggleButtonGroup
        exclusive
        value={buttonSelected}
        onChange={handleButtonChange}
        ref={ref}
        {...toggleButtonGroupProps}
      >
        {toggleButtons.map((toggleButton, index) => {
          return (
            <MUIToggleButton
              {...toggleButtonProps}
              {...borderForToggleButton(index)}
              key={index}
              value={toggleButton.value}
            >
              {toggleButton.label}
            </MUIToggleButton>
          );
        })}
      </MUIToggleButtonGroup>
    );
  }
);
