import type { SolutionActionType } from "../../../types";
import type { ActionApp, ActionStore } from "../../../types/common";
import type { PushWrapperFn } from "../../../types/scenarioAnalytics";
import { toGlobalId } from "../globalId";
import { getUrlAccordingToOs } from "../osUrl";

type ActionTypeAppOrStore = ActionApp & ActionStore;

type HandleStoreOrAppActionArgs = {
  isNotAMobile: boolean;
  id: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  actionType: SolutionActionType.app | SolutionActionType.store;
  routerPush: PushWrapperFn;
  toPageKey?: string;
  fromIntentId?: string;
} & ActionTypeAppOrStore;

export const handleStoreOrAppAction = async ({
  isNotAMobile,
  id,
  shortCode,
  scenarioId,
  actionType,
  os,
  iosLink,
  appStoreLink,
  androidLink,
  playStoreLink,
  routerPush,
  toPageKey,
  fromIntentId,
}: HandleStoreOrAppActionArgs) => {
  try {
    if (isNotAMobile) {
      const encodedId = toGlobalId("ScenarioVersionChildSolution", id);
      const url = shortCode ? `/c/${shortCode}/s/${encodedId}` : `/p/${scenarioId}/s/${encodedId}`;
      await routerPush(url, { toPageKey, fromIntentId });
      return false;
    } else {
      const urlAccordingToOs = getUrlAccordingToOs(actionType, {
        os,
        iosLink,
        androidLink,
        appStoreLink,
        playStoreLink,
      });
      window.open(urlAccordingToOs, "_blank");
      return true;
    }
  } catch (_) {
    return false;
  }
};
