export enum availableFonts {
  Kanit = "Kanit",
  Poppins = "Poppins",
  Roboto = "Roboto",
  TitilliumWeb = "Titillium+Web",
}

const addDefaultFonts = (font: availableFonts) => `${font},-apple-system,sans-serif`;

export const FONT_ID_TO_FONT_FAMILY_MAP: Record<availableFonts, string> = {
  [availableFonts.Kanit]: addDefaultFonts(availableFonts.Kanit),
  [availableFonts.Poppins]: addDefaultFonts(availableFonts.Poppins),
  [availableFonts.Roboto]: addDefaultFonts(availableFonts.Roboto),
  [availableFonts.TitilliumWeb]: addDefaultFonts(availableFonts.TitilliumWeb),
};

export const FONT_ID_TO_FONT_CDN_PATH_MAP: Record<availableFonts, string> = {
  [availableFonts.Kanit]: "/fonts/dialonce/kanit.css",
  [availableFonts.Poppins]: "/fonts/dialonce/poppins.css",
  [availableFonts.Roboto]: "/fonts/dialonce/roboto.css",
  [availableFonts.TitilliumWeb]: "/fonts/dialonce/titillium.css",
};
