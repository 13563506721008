import type { ScenarioVersionChildSolutionActionLiveChat } from "@dial-once/ich-seed";
import { parseUserAgent } from "../../../common/userAgent";
import type { ActionTypeAppOS, DynamicUrls, PushWrapperFn } from "../../../types";
import { ScenarioAnalyticsDocumentActionType, SolutionActionType } from "../../../types";
import type {
  ScenarioVersionChildSolutionActionApp,
  ScenarioVersionChildSolutionActionContextualizedRedirection,
  ScenarioVersionChildSolutionActionEmail,
  ScenarioVersionChildSolutionActionInternalFaq,
  ScenarioVersionChildSolutionActionPhoneNumber,
  ScenarioVersionChildSolutionActionStore,
  ScenarioVersionChildSolutionUrl,
} from "../../../types/api";
import type {
  ApiScenarioPageIntentTo,
  ApiScenarioPageToPage,
  ApiScenarioPageToSolution,
} from "../../../types/api/scenarioVersionChildren";
import { ToPageType } from "../../../types/api/scenarioVersionChildren";
import {
  checkIfUrlActionType,
  getOsFromParsedUserAgent,
  handleContextualizedRedirection,
  handleEmailAction,
  handleIFrameAction,
  handleInternalFaqAction,
  handleIvrAction,
  handlePostalMailAction,
  handleSolutionAnalytic,
  handleStoreOrAppAction,
  handleUrlAction,
  postScenarioDetails,
} from "../../helpers";
import { getOpenTypeFromApiOpenType } from "../../types/migration";
import { handleLiveChatAction } from "../router/handleLiveChatAction";

export type handleClickIntentArgs = {
  to: ApiScenarioPageIntentTo | null;
  intentId: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  routerPush: PushWrapperFn;
  branch?: string;
  dynamicUrls: DynamicUrls;
};

const handleClickIntent = async ({
  to,
  shortCode,
  scenarioId,
  routerPush,
  intentId: fromIntentId,
  branch,
  dynamicUrls,
}: handleClickIntentArgs) => {
  let parsedUserAgent: ReturnType<typeof parseUserAgent> | undefined;
  try {
    parsedUserAgent = parseUserAgent(navigator.userAgent);
  } catch {
    parsedUserAgent = undefined;
  }
  const isNotAMobile = parsedUserAgent?.platform?.type !== 2;

  if (!scenarioId && !shortCode) return;
  if (to && to.type === ToPageType.solution && (to as ApiScenarioPageToSolution).solution) {
    const { solution, key: toPageKey, id } = to as ApiScenarioPageToSolution;
    if (!solution) return;
    const { actionType } = solution;
    const isIvrSolutionAction =
      actionType === SolutionActionType.ivrCallHead ||
      actionType === SolutionActionType.ivrCallOriented;
    const isAppOrStoreSolutionAction =
      actionType === SolutionActionType.app || actionType === SolutionActionType.store;

    let solutionHasBeenExecuted = false;
    if (checkIfUrlActionType({ actionType })) {
      const { url } = solution as ScenarioVersionChildSolutionUrl;
      //TMP hotfix GMF bug
      void postScenarioDetails({
        type: ScenarioAnalyticsDocumentActionType.Push,
        shortCode,
        toPageKey,
        fromIntentId,
      });
      // TODO : Check if solution.id is base64 encoded or not
      solutionHasBeenExecuted = handleUrlAction({ url });
    } else if (actionType === SolutionActionType.contextualizedRedirection && branch) {
      const { location, openType } =
        solution as ScenarioVersionChildSolutionActionContextualizedRedirection;
      await postScenarioDetails({
        type: ScenarioAnalyticsDocumentActionType.Push,
        shortCode,
        toPageKey,
        fromIntentId,
      });
      solutionHasBeenExecuted = handleContextualizedRedirection({
        branch,
        location,
        openType: getOpenTypeFromApiOpenType(openType),
        dynamicUrls,
      });
    } else if (actionType === SolutionActionType.email) {
      const {
        to: toEmail,
        subject,
        body,
        cc,
        bcc,
      } = solution as ScenarioVersionChildSolutionActionEmail;
      await postScenarioDetails({
        type: ScenarioAnalyticsDocumentActionType.Push,
        shortCode,
        toPageKey,
        fromIntentId,
      });
      solutionHasBeenExecuted = handleEmailAction({ to: toEmail, subject, body, cc, bcc });
    } else if (
      actionType === SolutionActionType.internalFAQ &&
      (solution as ScenarioVersionChildSolutionActionInternalFaq).pageKey
    ) {
      const { pageKey } = solution as ScenarioVersionChildSolutionActionInternalFaq;
      solutionHasBeenExecuted = await handleInternalFaqAction({
        pageKey,
        shortCode,
        scenarioId,
        routerPush,
        fromIntentId,
      });
    } else if (isIvrSolutionAction) {
      const { phoneNumber } = solution as ScenarioVersionChildSolutionActionPhoneNumber;
      const toPageKey = `solution-${id}`;
      solutionHasBeenExecuted = await handleIvrAction({
        id,
        shortCode,
        scenarioId,
        routerPush,
        phoneNumber,
        toPageKey,
        fromIntentId,
      });
    } else if (actionType === SolutionActionType.postalMail) {
      const toPageKey = `solution-${id}`;
      solutionHasBeenExecuted = await handlePostalMailAction({
        id,
        shortCode,
        scenarioId,
        routerPush,
        toPageKey,
        fromIntentId,
      });
    } else if (isAppOrStoreSolutionAction) {
      const { actionType } = solution;
      const { iosLink, androidLink } = solution as ScenarioVersionChildSolutionActionApp;
      const { appStoreLink, playStoreLink } = solution as ScenarioVersionChildSolutionActionStore;
      const toPageKey = `solution-${id}`;
      solutionHasBeenExecuted = await handleStoreOrAppAction({
        isNotAMobile,
        id,
        shortCode,
        scenarioId,
        actionType: actionType as SolutionActionType.app | SolutionActionType.store,
        routerPush,
        appStoreLink,
        playStoreLink,
        os: (parsedUserAgent
          ? getOsFromParsedUserAgent(parsedUserAgent)
          : "other") as ActionTypeAppOS,
        androidLink,
        iosLink,
        toPageKey,
        fromIntentId,
      });
    } else if (actionType === SolutionActionType.liveChat) {
      const { url } = solution as ScenarioVersionChildSolutionActionLiveChat;
      handleLiveChatAction({ url });
      /* Two cases are possible, if it is an Iadvize solution, the helper above won't do anything,
      but we still have to send the analytics, so solutionHasBeenExecuted must be set to true.  */
      solutionHasBeenExecuted = true;
    } else if (actionType === SolutionActionType.iFrame) {
      solutionHasBeenExecuted = await handleIFrameAction({
        id,
        shortCode,
        scenarioId,
        routerPush,
        toPageKey,
        fromIntentId,
      });
    }
    if (solutionHasBeenExecuted) {
      await handleSolutionAnalytic({ type: actionType, solutionId: id, shortCode });
    }
  } else if (to && to.type === ToPageType.page) {
    const url = shortCode
      ? `/c/${shortCode}/${(to as ApiScenarioPageToPage).key}`
      : `/p/${scenarioId}/${(to as ApiScenarioPageToPage).key}`;
    await routerPush(url, { toPageKey: (to as ApiScenarioPageToPage).key, fromIntentId });
  } else {
    // eslint-disable-next-line no-console
    console.error("Invalid To provided");
  }
};

export default handleClickIntent;
