import { useState } from "react";
import { Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { MenuLink } from "../ui";
import type { ScenarioNavigationItem } from "../../types";

export type Props = {
  activeIntentIds: string[];
  intent: ScenarioNavigationItem;
  menuIntents: { [key: string]: ScenarioNavigationItem[] };
  noMarginBottom: boolean;
  onLinkClick: () => void;
};

const wrapperCss = {
  marginBottom: "1rem",
};

const headerCss = {
  display: "inline-flex",
  alignItems: "center",
  outline: "none",
};

const iconCss = {
  width: "1.5rem",
  height: "1.5rem",
  color: "#fff",
  cursor: "pointer",
};

const childWrapperCss = {
  padding: "0.5rem 0.5rem 0rem 2rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
};

const iconButtonCss = {
  marginTop: "2px",
  marginRight: "0.1rem",
  outline: "none",
};

const MenuItem = ({
  activeIntentIds,
  intent,
  menuIntents,
  onLinkClick,
  noMarginBottom = false,
}: Props) => {
  let intents: ScenarioNavigationItem[] | [] = intent?.to?.key
    ? menuIntents[intent?.to?.key] ?? []
    : [];
  if (intents.length > 1)
    intents = intents.sort((intent, intent2) => {
      if (intent.blockPosition && intent2.blockPosition) {
        if (intent.blockPosition === intent2.blockPosition)
          return intent.position - intent2.position;

        return intent.blockPosition - intent2.blockPosition;
      }
      if (intent.blockPosition) return intent.blockPosition - intent2.position;

      if (intent2.blockPosition) return intent.position - intent2.blockPosition;

      return intent.position - intent2.position;
    });

  const isActive = activeIntentIds.includes(intent.id);
  const [isOpen, setIsOpen] = useState(isActive);

  const conditionalStyle = noMarginBottom ? undefined : wrapperCss;

  /* eslint-disable @typescript-eslint/no-use-before-define */
  return (
    <Box
      sx={{
        ...conditionalStyle,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Box sx={headerCss}>
        {intents.length > 0 && (
          <Box component="span" onClick={() => setIsOpen(!isOpen)} sx={iconButtonCss}>
            {isOpen ? <ArrowDropDownIcon sx={iconCss} /> : <ArrowRightIcon sx={iconCss} />}
          </Box>
        )}
        <MenuLink
          name={intent.name}
          title={intent.displayTitle}
          onClick={() => {
            onLinkClick();
            intent.onClick();
          }}
          isActive={isActive}
        />
      </Box>
      {isOpen && intents.length > 0 && (
        <Box sx={childWrapperCss}>
          {intents.map((child) => {
            if (child?.to?.key && menuIntents[child?.to?.key] != null) {
              return (
                <MenuItem
                  activeIntentIds={activeIntentIds}
                  key={child.id}
                  intent={child}
                  onLinkClick={onLinkClick}
                  menuIntents={menuIntents}
                  noMarginBottom={false}
                />
              );
            }
            return (
              <Box
                key={child.id}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                <MenuLink
                  name={child.name}
                  title={child.displayTitle}
                  onClick={() => {
                    onLinkClick();
                    child.onClick();
                  }}
                  isActive={false}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
  /* eslint-enable  @typescript-eslint/no-use-before-define */
};

export default MenuItem;
