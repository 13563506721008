import type { FC } from "react";
import type { IAdvizeDisplayByDefault } from "../../types";

export type Props = {
  onlineId?: string;
  offlineId?: string;
  onlineContent?: string;
  offlineContent?: string;
  solutionId?: string;
  shortCode?: string;
  displayByDefault?: IAdvizeDisplayByDefault;
  onClick?: () => void;
};

const IAdvizeNode: FC<Props> = ({
  onlineId,
  offlineId,
  onlineContent,
  offlineContent,
  displayByDefault,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
    // eslint-disable-next-line no-console
    else console.error("No click function specified");
  };

  if (onlineId && onlineContent && offlineId && offlineContent) {
    const htmlOnlineContent = { __html: onlineContent };
    const htmlOfflineContent = { __html: offlineContent };
    return (
      <div className="idz_btn_fix">
        <button
          id={onlineId}
          style={{ display: displayByDefault === 1 ? "block" : "none", width: "100%" }}
          dangerouslySetInnerHTML={htmlOnlineContent}
          onClick={handleClick}
        />
        <button
          id={offlineId}
          style={{ display: displayByDefault === 2 ? "block" : "none", width: "100%" }}
          dangerouslySetInnerHTML={htmlOfflineContent}
          onClick={handleClick}
        />
      </div>
    );
  }
  return null;
};

export default IAdvizeNode;
