import { Box } from "@mui/material";
import type { FC } from "react";

type Props = {
  content: string;
};

const Footer: FC<Props> = ({ content }) => {
  return <Box dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Footer;
