import type { Router } from "next/router";
import { useEffect } from "react";
import type { ScenarioParams } from "../stores";
import { mergeScenarioParams } from "../stores";

const useRecoverParamsFromUrl = (router: Router) => {
  useEffect(() => {
    if (router.isReady) {
      const { scenarioId, shortCode, integrationType, branch, pageKey, solutionId, pinCode } =
        router.query as ScenarioParams;

      mergeScenarioParams({
        scenarioId,
        shortCode,
        integrationType,
        branch,
        pageKey,
        solutionId,
        pinCode,
      });
    }
  }, [router.isReady, router.query]);
};

export default useRecoverParamsFromUrl;
