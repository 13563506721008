import type { IAdvizeScriptObject } from "..";
import { ScenarioAnalyticsDocumentActionType } from "..";
import { postScenarioDetails } from "./requests";

export type IAdvizeConfig = Required<IAdvizeScriptObject>["iadvize"];

export const getScriptURL = ({ sid, iadvizeUrl }: IAdvizeConfig) => {
  const fullIAdvizeURL = `https://${iadvizeUrl || "live.iadvize.com"}/iadvize.js?`;
  const scriptURL = new URL(fullIAdvizeURL);
  scriptURL.searchParams.append("sid", sid);
  scriptURL.searchParams.append("lang", "fr");
  scriptURL.searchParams.append("useExplicitCookiesConsent", "true");
  return scriptURL.toString();
};

export const initIAdvize = (
  script: IAdvizeConfig,
  onChatEvent: () => void,
  shortCode?: string
): string => {
  const onlineButton = document.getElementById(script.onlineId);
  const offlineButton = document.getElementById(script.offlineId);

  if (onlineButton && offlineButton) {
    window.iAdvizeInterface ||= [];
    window.iAdvizeCallbacks = {
      onChatDisplayed: function () {
        onChatEvent();
      },
      onChatButtonDisplayed: function () {
        onChatEvent();
      },
    };
    window.iAdvizeCallbacks.onChatStarted = (context) => {
      onChatEvent();
      void postScenarioDetails({
        type: ScenarioAnalyticsDocumentActionType.IAdvize,
        shortCode,
        IAdvizeDetails: {
          vuid: context.vuid,
          conversationId: context.conversationId,
        },
      });
    };
  }

  return getScriptURL(script);
};
