import { useMemo } from "react";
import type { ScenarioData } from "../../types";
import { NodeType } from "../../types";

export default function useIsSingleSolution(scenarioData: ScenarioData) {
  return useMemo(
    () => scenarioData.children.length === 1 && scenarioData.children[0].type === NodeType.solution,
    [scenarioData.children]
  );
}
