import { forwardRef } from "react";
import type { BorderColor, BorderRadius, Elevation } from "../../../types";

import type { TableContainerProps as MUITableContainerProps } from "@mui/material";
import { TableContainer as MUITableContainer } from "@mui/material";
import {
  borderColorConverter,
  borderRadiusConverter,
  elevationConverter,
} from "../../../constants";

export type TableContainerProps = MUITableContainerProps & {
  rounded?: BorderRadius;
  border?: boolean;
  borderTheme?: BorderColor;
  elevation?: Elevation;
};

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  ({ rounded, border = false, borderTheme = "light", elevation, sx, ...props }, ref) => {
    return (
      <MUITableContainer
        ref={ref}
        sx={{
          ...(border && {
            border: 1,
            borderColor: borderColorConverter[borderTheme],
          }),
          borderRadius: rounded ? borderRadiusConverter[rounded] : undefined,
          boxShadow: elevation ? elevationConverter[elevation] : undefined,
          ...sx,
        }}
        {...props}
      />
    );
  }
);
