import { availableFonts, dialonceSmallLogoUrl } from "../constants";
import type { ChatbotParams } from "../schemas/dochatConfig";

export const chatbotParamsDefaultValues: Required<
  Pick<
    ChatbotParams,
    | "colorBackgroundMessages"
    | "colorPrimary1"
    | "colorPrimary2"
    | "showPoweredByDialonce"
    | "fontId"
    | "logoDataUri"
    | "placeholder"
    | "rasaContext"
    | "title"
  >
> = {
  colorBackgroundMessages: "#e7e9f1",
  colorPrimary1: "#262a3e",
  colorPrimary2: "#ed614c",
  showPoweredByDialonce: true,
  fontId: availableFonts.Roboto,
  logoDataUri: dialonceSmallLogoUrl,
  placeholder: "How can I help you?",
  rasaContext: {},
  title: "Client service",
};
