import type { SxProps, TypographyProps } from "@mui/material";
import { Typography as MUITypography } from "@mui/material";
import { forwardRef } from "react";
import { truncateHorizontalStyle, truncateVerticalStyle, variants } from "../../constants";

import type { TextColor, TextSize, TextVariant } from "../../types";

type TextProps = Omit<TypographyProps, "variant"> & {
  color?: TextColor;
  truncate?: boolean;
  numberOfLines?: number;
  size?: TextSize;
  variant?: TextVariant;
};

export const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      component = "p",
      color = "black",
      truncate,
      numberOfLines = 0,
      size = "md",
      sx,
      variant = "paragraph",
      ...props
    },
    ref
  ) => {
    return (
      <MUITypography
        ref={ref}
        variant={size}
        component={component}
        sx={
          {
            ...(truncate ? truncateHorizontalStyle : {}),
            ...(numberOfLines > 0 ? truncateVerticalStyle(numberOfLines) : {}),
            ...variants[variant],
            ...(typeof component === "string" && ["strong", "b"].includes(component)
              ? { fontWeight: 700 }
              : undefined),
            ...sx,
          } as SxProps
        }
        color={color}
        {...props}
      />
    );
  }
);

export default Text;
