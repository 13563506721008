import { forwardRef } from "react";
import type { ContainerProps as MUIContainerProps } from "@mui/material";
import { Container as MUIContainer } from "@mui/material";

export type ContainerProps = Omit<MUIContainerProps, "paddingY"> & {
  paddingY?: boolean;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ maxWidth = "xl", paddingY = true, sx = {}, ...props }, ref) => {
    const styles: MUIContainerProps["sx"] = {
      ...sx,
      ...(paddingY ? { paddingY: { xs: "32px", sm: "48px", lg: "64px" } } : {}),
    };

    return <MUIContainer ref={ref} maxWidth={maxWidth} sx={styles} {...props} />;
  }
);
