import type { ActionApp, ActionStore } from "../../../types/common";
import type { PushWrapperFn } from "../../../types/scenarioAnalytics";
import { toGlobalId } from "../globalId";

type ActionTypeAppOrStore = ActionApp & ActionStore;

type HandleCodeActionArgs = {
  id: string;
  shortCode: string | undefined;
  scenarioId: string | undefined;
  routerPush: PushWrapperFn;
  code?: string | undefined;
  toPageKey?: string;
  fromIntentId?: string;
} & ActionTypeAppOrStore;

export const handleCodeAction = async ({
  id,
  shortCode,
  scenarioId,
  routerPush,
}: HandleCodeActionArgs) => {
  const encodedId = toGlobalId("ScenarioVersionChildSolution", id);
  const url = shortCode ? `/c/${shortCode}/s/${encodedId}` : `/p/${scenarioId}/s/${encodedId}`;
  await routerPush(url);
  return false;
};
