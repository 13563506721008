import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { markdown } from "@codemirror/lang-markdown";
import type { BoxProps } from "@mui/material";
import { Box, styled } from "@mui/material";
import { copilot } from "@uiw/codemirror-theme-copilot";
import CodeMirror, { type ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { forwardRef, memo } from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  maxHeight: "100%",
  overflow: "hidden",
  opacity: 1,
  transition: "opacity 300ms ease-in-out",
  "&.disabled": {
    opacity: 0.75,
  },
}));

export enum Languages {
  html = "html",
  javascript = "javascript",
  json = "json",
  markdown = "markdown",
}

const languageSupported = {
  [Languages.html]: {
    placeholder: "Enter your HTML",
    extension: html,
  },
  [Languages.javascript]: {
    placeholder: "Enter your Javascript",
    extension: javascript,
  },
  [Languages.json]: {
    placeholder: "Enter your JSON",
    extension: json,
  },
  [Languages.markdown]: {
    placeholder: "Enter your Markdown",
    extension: markdown,
  },
};

type CodeEditorProps = BoxProps & {
  disabled?: boolean;
  editorOptions?: ReactCodeMirrorProps["basicSetup"];
  language?: Languages;
  onChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  theme?: ReactCodeMirrorProps["theme"];
  value?: string;
};

export const CodeEditor = memo(
  forwardRef<HTMLDivElement, CodeEditorProps>(
    (
      {
        disabled,
        editorOptions = true,
        height,
        language = Languages.json,
        onChange,
        placeholder,
        readOnly,
        theme = copilot,
        value,
        ...boxProps
      },
      ref
    ) => (
      <Wrapper
        ref={ref}
        className={disabled ? "disabled" : undefined}
        fontSize="0.8em"
        height={height}
        {...boxProps}
      >
        <CodeMirror
          basicSetup={editorOptions}
          extensions={[languageSupported[language].extension()]}
          height={typeof height === "number" ? `${height}px` : undefined}
          onChange={onChange}
          placeholder={placeholder || languageSupported[language].placeholder}
          readOnly={readOnly || disabled}
          theme={theme}
          value={value}
        />
      </Wrapper>
    )
  )
);
CodeEditor.displayName = "CodeEditor";
