import type { Shadows, ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material";
import { size } from "polished";

type T = string | number | { [k: string]: T };
declare module "@mui/material" {
  interface Palette {
    border: Palette["primary"];
  }
  interface PaletteOptions {
    border?: PaletteOptions["primary"];
  }
  interface Theme {
    bouncingDots?: ThemeOptions["bouncingDots"];
    iconDropZone?: ThemeOptions["iconDropZone"];
  }
  interface ThemeOptions {
    bouncingDots?: Record<string, T>;
    iconDropZone?: Record<string, T>;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    "2xl": true;
    "3xl": true;
    "4xl": true;
    "5xl": true;
  }
}

export const PRIMARY_DARK = "#1E0C1F";
export const PRIMARY_MAIN = "#340f38";
export const PRIMARY_LIGHT = "#681F5B";

export const SECONDARY_DARK = "#F4550E";
export const SECONDARY_MAIN = "#ff733b";
export const SECONDARY_LIGHT = "#FFAC8D";

export const ERROR_DARK = "#B91C1C";
export const ERROR_MAIN = "#EF4444";
export const ERROR_LIGHT = "#FCA5A5";

export const SUCCESS_DARK = "#047857";
export const SUCCESS_MAIN = "#10B981";
export const SUCCESS_LIGHT = "#6EE7B7";

export const WARNING_DARK = "#CA8A04";
export const WARNING_MAIN = "#FACC15";
export const WARNING_LIGHT = "#FEF08A";

export const WHITE = "#fff";
export const BLACK = "#030712";

export const GREY_50 = "#f9fafb";
export const GREY_100 = "#f3f4f6";
export const GREY_200 = "#e5e7eb";
export const GREY_300 = "#d1d5db";
export const GREY_400 = "#9ca3af";
export const GREY_500 = "#6b7280";
export const GREY_600 = "#4b5563";
export const GREY_700 = "#374151";
export const GREY_800 = "#1f2937";
export const GREY_900 = "#111827";

export const GREY = GREY_200;
export const LIGHT_GREY = GREY_100;

export const BASE_SPACING = 8;
export const BASE_RADIUS = 8;

export const headerDefault = {
  fontFamily: `'Nunito Sans', 'Roboto', Arial, Segoe UI, sans-serif`,
};

export const shadows = [
  "none",
  "0 1px 2px 0 rgb(0, 0, 0, 0.05)",
  "0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1)",
  "0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)",
  "0 10px 15px -3px rgb(0, 0, 0, 0.1), 0 4px 6px -4px rgb(0, 0, 0, 0.1)",
  "0 20px 25px -5px rgb(0, 0, 0, 0.1), 0 8px 10px -6px rgb(0, 0, 0, 0.1)",
  "0 25px 50px -12px rgb(0, 0, 0, 0.25)",
  ...Array(18).fill("none"),
] as Shadows;

export const labelWeight = 500;
export const paragraphWeight = 400;

export const defaultTemplateOptions: ThemeOptions = {
  palette: {
    primary: {
      dark: PRIMARY_DARK,
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
    },

    secondary: {
      dark: SECONDARY_DARK,
      main: SECONDARY_MAIN,
      light: SECONDARY_LIGHT,
    },

    error: {
      dark: ERROR_DARK,
      main: ERROR_MAIN,
      light: ERROR_LIGHT,
    },

    success: {
      dark: SUCCESS_DARK,
      main: SUCCESS_MAIN,
      light: SUCCESS_LIGHT,
    },

    warning: {
      dark: WARNING_DARK,
      main: WARNING_MAIN,
      light: WARNING_LIGHT,
    },

    common: {
      white: WHITE,
      black: BLACK,
    },

    border: {
      dark: GREY_400,
      main: GREY_300,
      light: GREY_200,
    },

    grey: {
      50: GREY_50,
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      800: GREY_800,
      900: GREY_900,
    },
  },
  typography: {
    fontFamily: "Open Sans, Roboto, Public, sans-serif",
    h1: {
      ...headerDefault,
    },
    h2: {
      ...headerDefault,
    },
    h3: {
      ...headerDefault,
    },
    h4: {
      ...headerDefault,
    },
    h5: {
      ...headerDefault,
    },
    h6: {
      ...headerDefault,
    },
    button: {
      fontWeight: 600,
    },
  },
  spacing: BASE_SPACING,
  shadows,
  shape: {
    borderRadius: BASE_RADIUS,
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            cursor: "unset",
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: shadows[4],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "grey.200",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root fieldset": {
            borderColor: GREY_300,
          },
          "& .MuiInputBase-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover fieldset":
            {
              borderColor: GREY_500,
            },
          "& .Mui-focused .MuiInputBase-root": {
            borderColor: PRIMARY_MAIN,
          },
          "& .MuiInputBase-input::placeholder": {
            color: GREY_400,
            opacity: 1,
          },
          "& .MuiInputLabel-root": {
            color: GREY_500,
          },
          "& .Mui-disabled fieldset": {
            borderStyle: "dotted",
            borderColor: GREY_400,
            color: GREY_400,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: GREY_500,
          "&.Mui-focused": {
            color: PRIMARY_MAIN,
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "xs" },
          style: {
            fontSize: 12,
            lineHeight: 16,
            letterSpacing: "0.1px",
          },
        },
        {
          props: { variant: "sm" },
          style: {
            fontSize: 14,
            lineHeight: 20,
            letterSpacing: "0.1px",
          },
        },
        {
          props: { variant: "md" },
          style: {
            fontSize: 16,
            lineHeight: 24,
            letterSpacing: "0.15px",
          },
        },
        {
          props: { variant: "lg" },
          style: {
            fontSize: 18,
            lineHeight: 28,
            letterSpacing: "0.15px",
          },
        },
        {
          props: { variant: "xl" },
          style: {
            fontSize: 20,
            lineHeight: 20,
            letterSpacing: "0.15px",
          },
        },
        {
          props: { variant: "2xl" },
          style: {
            fontSize: 24,
            lineHeight: 32,
            letterSpacing: 0,
          },
        },
        {
          props: { variant: "3xl" },
          style: {
            fontSize: 30,
            lineHeight: 36,
            letterSpacing: 0,
          },
        },
        {
          props: { variant: "4xl" },
          style: {
            fontSize: 48,
            lineHeight: 54,
            letterSpacing: 0,
          },
        },
        {
          props: { variant: "5xl" },
          style: {
            fontSize: 60,
            lineHeight: 66,
            letterSpacing: "-0.5px",
          },
        },
      ],
    },

    MuiTable: {
      styleOverrides: {
        root: {
          "&.withoutLastBorder .MuiTableBody-root .MuiTableRow-root:last-child td, &.withoutLastBorder .MuiTableBody-root .MuiTableRow-root:last-child th":
            { border: 0 },
          "&.borderless td, &.borderless th": {
            border: 0,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: labelWeight,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: paragraphWeight,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "& td, th": {
            borderColor: GREY_300,
          },
          "&.borderless td, &.borderless th": {
            border: 0,
          },
          "&.MuiTableRow-hover:hover": {
            backgroundColor: GREY_100,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 300,
          padding: "1rem 1.5rem",
          fontSize: "1rem",
          borderRadius: "0.5rem",
          backgroundColor: WHITE,
          color: "black",
          boxShadow:
            "#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          width: "30%",
          paddingRight: "1rem",
          "& li": {
            padding: "0.5rem ",
          },
          "& label": {
            fontSize: ".8rem",
          },
        },
        tagSizeSmall: {
          fontSize: "0.7rem",
          "&::placeholder": {
            fontSize: "0.7rem",
          },
        },
        inputRoot: {
          border: "2px solid rgba(46, 18, 48, 0.2)",
          borderRadius: 0,
          paddingLeft: 8,
          opacity: 0.5,
          fontSize: "0.7rem",
          "& li:hover": {
            backgroundColor: PRIMARY_MAIN,
            cursor: "pointer",
            fontWeight: 700,
          },
        },
        listbox: {
          backgroundColor: WHITE,
          border: "1px solid #c1c0bb",
          borderTopWidth: 0,
          listStyle: "none",
          marginTop: 0,
          maxHeight: 143,
          overflowY: "auto",
          paddingLeft: 0,
          fontSize: "0.7rem",
          zIndex: 1000,
        },
        groupLabel: {
          fontSize: "0.7rem",
          color: "green",
        },
        option: {
          fontSize: "0.7rem",
          "&:hover": {
            fontWeight: 700,
          },
        },
        noOptions: {
          fontSize: "0.7rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: shadows[2],
          "&:hover": {
            boxShadow: shadows[2],
          },
          "&:focus": {
            boxShadow: shadows[2],
          },
        },
        containedPrimary: {
          color: "white",
        },
        containedSecondary: {
          color: "white",
        },
        containedError: {
          color: "white",
        },
        containedSuccess: {
          color: "white",
        },
        containedWarning: {
          color: "white",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            width: "60%",
            minWidth: 300,
            minHeight: 200,
            padding: 30,
            borderRadius: 10,
            color: BLACK,
            overflow: "auto",
          },
          "& .MuiDialogTitle-root": {
            padding: 0,
            paddingBottom: 30,
          },
          "& .MuiDialogContent-root": {
            padding: 10,
            paddingBottom: 40,
            overflowY: "visible",
          },
        },
      },
    },
  },
  iconDropZone: {
    "& .square": {
      ...size(160),
      display: "inline-block",
      borderRadius: 5,
      border: "1px solid lightgray",
      marginRight: 4,
    },
    "& .existing-icon-line, & .replace-action": {
      display: "flex",
      alignItems: "center",
    },
    "& .replace-action": {
      borderBottom: "1px solid black",
      cursor: "pointer",
    },
    "& .helper": {
      fontSize: "90%",
      color: PRIMARY_LIGHT,
      marginTop: 4,
    },
    "& .placeholder, & .error": {
      textAlign: "center",
      fontSize: "90%",
    },
    "& .placeholder": {
      padding: 4,
    },
    "& .error": {
      padding: 12,
    },
  },
};

export const theme = createTheme(defaultTemplateOptions);

export type ThemeType = typeof theme;
