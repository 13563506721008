import { ExpandMore } from "@mui/icons-material";
import type { BoxProps as MUIBoxProps } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, Box as MUIBox } from "@mui/material";
import { size as polishedSize } from "polished";
import { forwardRef, useCallback, useState } from "react";
import { borderColorConverter, borderRadiusConverter, elevationConverter } from "../../constants";
import type { BorderColor, BorderRadius, Elevation } from "../../types";
import type { HeadingProps } from "../data-display";
import { Heading } from "../data-display";

export type BoxProps = Omit<MUIBoxProps, "border"> & {
  rounded?: BorderRadius;
  border?: boolean;
  borderTheme?: BorderColor;
  elevation?: Elevation;
  foldable?: boolean;
  size?: number;
  title?: HeadingProps["children"];
};

export const Box = forwardRef<unknown, BoxProps>(
  (
    {
      border = false,
      borderTheme = "light",
      children,
      elevation,
      foldable,
      rounded,
      size,
      title,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClick = useCallback(() => {
      setOpen((prevState) => !prevState);
    }, []);
    return (
      <MUIBox
        ref={ref}
        {...(border && {
          border: 1,
          borderColor: borderColorConverter[borderTheme],
        })}
        borderRadius={rounded ? borderRadiusConverter[rounded] : undefined}
        boxShadow={elevation ? elevationConverter[elevation] : undefined}
        {...(size ? polishedSize(size) : {})}
        {...props}
      >
        {foldable ? (
          <Accordion expanded={open} onChange={handleClick}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {title && (
                <Heading size="lg" component="h2">
                  {title}
                </Heading>
              )}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
          </Accordion>
        ) : (
          children
        )}
      </MUIBox>
    );
  }
);
