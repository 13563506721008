import type { FC } from "react";
import type { Action, SolutionActionType } from "../../types";

export type Props = {
  view: string;
  onClick: () => void;
  actionType: SolutionActionType;
  code?: string | undefined;
} & Action;

const SolutionNode: FC<Props> = ({ view, onClick, code }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <button
      className="ich-solution"
      style={{ width: "100%" }}
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: view }}
    />
  );
};

export default SolutionNode;
