import { Box, Chip } from "@mui/material";
import type {
  DateCalendarProps as MUIDateCalendarProps,
  PickersDayProps,
} from "@mui/x-date-pickers";
import {
  LocalizationProvider,
  DateCalendar as MUIDateCalendar,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export type DateCalendarProps = Omit<MUIDateCalendarProps<Date>, "value" | "onChange"> & {
  dateFormat: string;
  value?: Date[];
  onChange?: (dates: Date[]) => void;
};

export const DateCalendar: React.FC<DateCalendarProps> = ({
  dateFormat,
  value = [],
  onChange,
  ...props
}) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(value);

  useEffect(() => {
    setSelectedDates(value || []);
  }, [value]);

  const handleDateChange = (newDate: Date | null) => {
    if (!newDate) return;

    const exists = selectedDates.some((date) => dayjs(date).isSame(newDate, "day"));
    const updatedDates = exists
      ? selectedDates.filter((date) => !dayjs(date).isSame(newDate, "day"))
      : [...selectedDates, newDate];

    setSelectedDates(updatedDates);
    onChange?.(updatedDates);
  };

  const CustomDay = (props: PickersDayProps<Date>) => {
    const { day, selected, ...other } = props;
    const isSelected = selectedDates.some((selectedDate) => dayjs(selectedDate).isSame(day, "day"));

    return (
      <PickersDay
        {...other}
        day={day}
        selected={isSelected}
        sx={{
          backgroundColor: isSelected ? "primary.main" : undefined,
          color: isSelected ? "white" : undefined,
          "&:hover": {
            backgroundColor: isSelected ? "primary.dark" : "rgba(0, 0, 0, 0.04)",
          },
        }}
      />
    );
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MUIDateCalendar onChange={handleDateChange} slots={{ day: CustomDay }} {...props} />
      </LocalizationProvider>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {selectedDates.map((date, index) => (
          <Chip
            key={index}
            label={date.toLocaleDateString(dateFormat)}
            onDelete={() => handleDateChange(date)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DateCalendar;
